import Vue from "vue";
import VueRouter from "vue-router";
import PageHome from "@/pages/index.vue";
import authenticate from "@/router/guards/authenticate";

Vue.use(VueRouter);

export const ROUTE_NAMES = {
  HOME: "Home",
  LOGIN: "Login",
  ABOUT: "About",
  PARTNER: "Partner",
  MANAGER: "Manager",
  PERSONAL: "Personal",
  PERSONAL_WAGER: "PersonalWager",
  PERSONAL_PROFILE: "PersonalProfile",
  PERSONAL_SET_SEX: "PersonalSetSex",
  PERSONAL_SET_NAME: "PersonalSetName",
  PERSONAL_SET_BANK_ACCOUNT: "PersonalSetBankAccount",
  PERSONAL_SET_PASSWORD: "PersonalSetPassword",
  WORK_DETAIL: "WorkDetail",
  LOTTERY: "Lottery",
  LOTTERY_DETAIL: "LotteryDetail",
};

const routes = [
  {
    path: "/",
    name: ROUTE_NAMES.HOME,
    meta: {
      requiresAuth: true,
    },
    component: PageHome,
  },
  {
    path: "/partner",
    name: ROUTE_NAMES.PARTNER,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/partner.vue"),
  },
  {
    path: "/manager",
    name: ROUTE_NAMES.MANAGER,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/manager.vue"),
  },
  {
    path: "/work-detail/:id",
    name: ROUTE_NAMES.WORK_DETAIL,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/work-detail.vue"),
  },
  {
    path: "/personal",
    name: ROUTE_NAMES.PERSONAL,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/personal/index.vue"),
  },
  {
    path: "/personal/set-sex",
    name: ROUTE_NAMES.PERSONAL_SET_SEX,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/personal/set-sex.vue"),
  },
  {
    path: "/personal/set-name",
    name: ROUTE_NAMES.PERSONAL_SET_NAME,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/personal/set-name.vue"),
  },
  {
    path: "/personal/set-bank-account",
    name: ROUTE_NAMES.PERSONAL_SET_BANK_ACCOUNT,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/personal/set-bank-account.vue"),
  },
  {
    path: "/personal/wager",
    name: ROUTE_NAMES.PERSONAL_WAGER,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/personal/wager.vue"),
  },
  {
    path: "/personal/profile",
    name: ROUTE_NAMES.PERSONAL_PROFILE,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/personal/profile.vue"),
  },
  {
    path: "/lottery",
    name: ROUTE_NAMES.LOTTERY,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/lottery.vue"),
  },
  {
    path: "/lottery/:id(\\d+)",
    name: ROUTE_NAMES.LOTTERY_DETAIL,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/lottery-detail.vue"),
  },
  {
    path: "/about",
    name: ROUTE_NAMES.ABOUT,
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/pages/about.vue"),
  },
  {
    path: "/login",
    name: ROUTE_NAMES.LOGIN,
    component: () => import("@/pages/login.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authenticate);

export default router;
