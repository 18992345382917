import store from "@/store";
import { ROUTE_NAMES } from "@/router";

export default (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters.isAuthenticated;

  if (isAuthenticated) {
    store.dispatch("fetchMember").catch(() => {
      store.commit("setToken");
    });
  }

  if (requiresAuth && !isAuthenticated) {
    return next({
      name: ROUTE_NAMES.LOGIN,
    });
  }

  return next();
};
