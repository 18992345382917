import Vue from "vue";
import Vuex from "vuex";
import VuexPersistedstate from "vuex-persistedstate";
import { getMemberProfile } from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    member: {
      id: null,
      name: "",
      username: "",
      sex: "",
      sex_description: "",
      credit_score: 0,
      visa_score: 0,
      balance: 0,
      bankAccount: {
        bank_code: "",
        bank_name: "",
        account_name: "",
        account_number: "",
      },
      is_bind_bank_account: false,
    },
    config: {
      name: "",
      logo: "",
      support_url: "",
    },
    locale: process.env.VUE_APP_I18N_LOCALE || "vi",
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setMember(state, member) {
      state.member = member;
    },
    setConfig(state, config) {
      state.config = config;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    initialState(state) {
      state.token = "";
      state.member = {
        id: null,
        name: "",
        username: "",
        sex: "",
        sex_description: "",
        credit_score: 0,
        visa_score: 0,
        balance: 0,
        bankAccount: {
          bank_code: "",
          bank_name: "",
          account_name: "",
          account_number: "",
        },
        is_bind_bank_account: false,
      };
    },
  },
  actions: {
    async fetchMember({ commit }) {
      const response = await getMemberProfile();

      commit("setMember", response.data);
    },
  },
  plugins: [new VuexPersistedstate()],
});
