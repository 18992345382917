<template>
  <div class="main-layout">
    <van-nav-bar
      v-if="withNavbar"
      v-bind="navbarOptions"
      class="main-layout__navbar"
      fixed
      left-arrow
      placeholder
      safe-area-inset-top
      @click-left="onNavbarClickLeft"
    >
      <template #left>
        <img
          v-if="navbarOptions.showLogo"
          src="@/assets/images/logo.png"
          alt=""
          class="logo"
        />
      </template>
    </van-nav-bar>
    <slot />
    <van-tabbar v-if="withTabbar" class="main-layout__tabbar" route placeholder>
      <template v-for="item in tabbarItems">
        <van-tabbar-item :key="item.routeName" :to="{ name: item.routeName }">
          <template #icon="props">
            <img :src="props.active ? item.activeIcon : item.icon" />
          </template>
          {{ item.text }}
        </van-tabbar-item>
      </template>
    </van-tabbar>
  </div>
</template>

<script>
import { ROUTE_NAMES } from "@/router";

export default {
  name: "MainLayout",
  props: {
    withNavbar: {
      type: Boolean,
      default: false,
    },
    withTabbar: {
      type: Boolean,
      default: false,
    },
    navbarOptions: {
      type: Object,
      default: () => ({}),
    },
    tabbarOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabbarItems: [
        {
          icon: require("@/assets/images/menu-home.png"),
          activeIcon: require("@/assets/images/menu-home-active.png"),
          text: this.$t("首页"),
          routeName: ROUTE_NAMES.HOME,
        },
        {
          icon: require("@/assets/images/menu-cor.png"),
          activeIcon: require("@/assets/images/menu-cor-active.png"),
          text: this.$t("合作伙伴"),
          routeName: ROUTE_NAMES.PARTNER,
        },
        {
          icon: require("@/assets/images/menu-info.png"),
          activeIcon: require("@/assets/images/menu-info-active.png"),
          text: this.$t("信息"),
          routeName: ROUTE_NAMES.LOTTERY,
        },
        {
          icon: require("@/assets/images/menu-managers.png"),
          activeIcon: require("@/assets/images/menu-managers-active.png"),
          text: this.$t("公司团队"),
          routeName: ROUTE_NAMES.MANAGER,
        },
        {
          icon: require("@/assets/images/menu-personal.png"),
          activeIcon: require("@/assets/images/menu-personal-active.png"),
          text: this.$t("个人资料"),
          routeName: ROUTE_NAMES.PERSONAL,
        },
      ],
    };
  },
  methods: {
    onNavbarClickLeft() {
      if (this.navbarOptions.showLogo) {
        this.$router.push({ name: ROUTE_NAMES.HOME });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="less">
.main-layout {
  height: 100%;

  &__navbar {
    > .van-nav-bar {
      background-image: url("@/assets/images/bg-top.png");

      .van-icon {
        font-size: 22px;
      }
    }

    .logo {
      background: white;
      border-radius: 50%;
      height: 50px;
      padding: 6px;
      width: 50px;
    }
  }
}
</style>
