import axios from "axios";
import store from "@/store";
import router, { ROUTE_NAMES } from "@/router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  config.headers["X-Language"] = store.state.locale;

  if (store.getters.isAuthenticated) {
    config.headers["Authorization"] = "Bearer " + store.state.token;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      store.commit("initialState");
      router.push({ name: ROUTE_NAMES.LOGIN });
    }

    return Promise.reject(error);
  }
);

export function getLogin(data) {
  return instance.post("/member/login", data);
}

export function getWork(id) {
  return instance.get("/work/" + id);
}

export function getBanks() {
  return instance.get("/bank");
}

export function getWorks() {
  return instance.get("/work");
}

export function getAbout() {
  return instance.get("/about");
}

export function getPartner() {
  return instance.get("/partner");
}

export function getLottery(lotteryId) {
  return instance.get(`/lottery/${lotteryId}`);
}

export function getLotteries() {
  return instance.get("/lottery");
}

export function getEmployees() {
  return instance.get("/employee");
}

export function setMemberSex(data) {
  return instance.post("/member/set-sex", data);
}

export function setMemberName(data) {
  return instance.post("/member/set-name", data);
}

export function getWagers(data) {
  return instance.get("/wager", data);
}

export function createWager(data) {
  return instance.post("/wager/create", data);
}

export function getMemberProfile() {
  return instance.get("/member/profile");
}

export function setBankAccount(data) {
  return instance.post("/member/bind-bank-account", data);
}
