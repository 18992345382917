import Vue from "vue";
import currency from "currency.js";

const MONEY_PRECISION = 0;

Vue.directive("money-format", {
  bind(el, binding) {
    const options = {
      precision: MONEY_PRECISION,
      symbol: binding.modifiers["no-symbol"] ? "" : "$ ",
    };

    el.innerText = currency(binding.value, options).format();
  },
  update(el, binding) {
    const options = {
      precision: MONEY_PRECISION,
      symbol: binding.modifiers["no-symbol"] ? "" : "$ ",
    };

    el.innerText = currency(binding.value, options).format();
  },
});

Vue.directive("number-format", {
  bind(el, binding) {
    const options = {
      precision: MONEY_PRECISION,
      symbol: "",
    };

    el.innerText = currency(binding.value, options).format();
  },
  update(el, binding) {
    const options = {
      precision: MONEY_PRECISION,
      symbol: "",
    };

    el.innerText = currency(binding.value, options).format();
  },
});
