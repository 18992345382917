<template>
  <MainLayout with-navbar with-tabbar :navbar-options="navbarOptions">
    <div class="home">
      <div class="home__body">
        <template v-for="item in list">
          <div :key="item.id" class="home__section">
            <van-image :src="item.image" alt="" />

            <h2 class="home__title home__title--ellipsis">
              {{ item.title }}
            </h2>
            <div class="home__description" v-html="item.description" />
            <van-button
              block
              round
              type="primary"
              @click="redirectWorkDetail(item)"
            >
              {{ $t("查看详情") }}
            </van-button>
          </div>
        </template>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { getWorks } from "@/api";
import { ROUTE_NAMES } from "@/router";
import MainLayout from "@/layouts/main-layout.vue";

export default {
  components: { MainLayout },
  data() {
    return {
      list: [],
      navbarOptions: {
        title: this.$t("首页"),
        showLogo: true,
      },
    };
  },
  mounted() {
    this.fetchWorks();
  },
  methods: {
    async fetchWorks() {
      try {
        const { data } = await getWorks();

        this.list = data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : this.$t("系统错误，请稍后再试");

        this.$toast(message);
      }
    },
    redirectWorkDetail(work) {
      this.$router.push({
        name: ROUTE_NAMES.WORK_DETAIL,
        params: { id: work.id },
      });
    },
  },
};
</script>

<style lang="less">
@import "@/assets/less/variable";

.home {
  margin: 16px;

  &__section {
    background-color: @white;
    border-radius: 8px;
    box-shadow: @base-shadow;
    margin-bottom: 16px;
    padding: 16px 18px;
  }

  &__title {
    font-size: 18px;

    &--ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__description {
    color: #8c8c8c;
    line-height: 2;
    margin-bottom: 20px;
  }
}
</style>
